#Cafe{
    padding-bottom: 0;
}
.c-s-img{
    width: 50%;
}
.c-s-img-l{
    margin-left: auto;
}
.c-row-d > img{
    width: 50%;
}

@media screen and (max-width:480px) {
    .c-g-10{
        gap:10px;
    }
    .c-g-0{
        gap:0;
    }
    .c-s-img{
        width: calc(50% - 5px);
    }
    .c-row-d{
        flex-direction: row;
    }
    .c-row-d > img{
        width: 100%;
    }
    #Cafe{
        padding-bottom: 60px;
    }
}