/* Monochrome */
.slider-item-img:hover{
    cursor: pointer;
}
.mm-rr-1{
    transform: translateY(230px);
}
.mm-rr-2{
    transform: translateY(210px);
}
.mm-rr-3{
    transform: translateY(-260px); 
}


@media screen and (max-width: 480px) {
    .project-container{
        padding-top: 180px;
        padding-bottom: 60px;
        width: calc(100% - 40px);
        padding-inline: 20px;
    }
    .mm-rr-1, .mm-rr-2, .mm-rr-3{
        transform: none;
    }
    
    .m-mb-4{
        flex-direction: column-reverse;
        gap:10px;
    }
    
    .m-mb-single{
        width: calc(50% - 5px);
    }

    .m-mb-5{
        flex-direction: column;
        gap:10px;
    }
    .m-6-d{
        flex-direction: row;
    }

}