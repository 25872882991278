.back-btn-container{
    display: flex;
    gap:10px;
    align-items: center;
    font-size: 20px;
    font-family: var(--font-jost);
    flex-direction: row;
    margin-bottom: 20px;

    position: absolute;
    left:60px;
    top:150px;
}
.back-btn-container:hover{
    cursor: pointer;
}
.back-btn-route-span{
    opacity: .5;
}
.back-btn-route-active{
    opacity: 1;
}

@media screen and (max-width:480px ){
    .back-btn-container{
        font-size: 16px;
        display: flex;
        top:100px;
        left:20px;
    }
}