.home-gallery-wrapper{
    width: 100%;
    min-height: 500px;
    padding: 250px 0 0 0;
}
.home-g-e-p{
    padding: 350px 0 60px 0;
}

.gallery-wrapper{
    display: flex;
    position: relative;
    gap:30px;
    width: calc(100% - 120px);
    margin: 0 auto;
}


.gallery-item{
    display: flex;
    align-items: flex-end;

    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    height: 580px;
    flex: 0.5;
    cursor: pointer;
    border-radius: 80px;

    transition: flex 700ms;
}


.gallery-item.gallery-active > h2{
    font-family: var(--font-jost);
    font-size: 30px;
    font-weight: 400;
    color:#fff;
    margin-left: 100px;
}

.gallery-active{
    flex: 5;
}

.gallery-item{
    position: relative;
}

.gallery-item::after{
    position: absolute;
    content: '';
    pointer-events: none;
    background-color: #fff;
    border: 1px solid #000;
    transition: all 500ms;
    height: 10px;
    top:-100px;
    width: calc(100% + 30px);
}

.g-private::after{
    border-left:none;
    border-right: none;
}
.g-private.g-last::after{
    width: 100%;
    border-radius: 0 10px 10px 0;
    border-right: 1px solid black;
}
.g-private.g-first::after{
    border-radius: 10px 0 0 10px;
    border-left: 1px solid black;
}

.g-public.g-first::after{
    border-radius: 10px 0 0 10px;
    border-right: none;
}
.g-public.g-last::after{
    width: 100%;
    border-radius: 0 10px 10px 0;
    border-left: none;
}

.g-green::after{
    background-color: var(--color-primary);
    height:12px ;
    border: none !important;
}

.g-green > .gallery-label{
    font-size: 30px;
}

.gallery-label{
    position: absolute;
    top:0;

    font-family: var(--font-jost);
    font-size: 20px;
    font-weight: 400;

    transition: all 250ms ease;
    top:-70px;
}

.gallery-item > .gallery-span{
    opacity: 0;
    position: absolute;
    bottom: 50px;
    left: 50px;
    font-size: 30px;
    font-family: var(--font-jost);
    color: #fff;
    transition: opacity 500ms ease-in-out;
    width: auto;
    white-space: nowrap;
}
.gallery-active > .gallery-span{
    opacity: 1;
}
.mobile-gallery-img{
    position: relative;
}
.mobile-gallery-span{
    bottom:20px;
    left: 20px;
    position: absolute;
    font-size: 20px;
    font-family: var(--font-jost);
    color: #fff;
}


@media screen and (max-width:480px) {
    .home-gallery-mobile{
        width: calc(100% - 40px);
        margin: 60px auto 50px auto;
        background-color: white;
    }
    .lines-indicator{
        width: 100%;
        height: 6px;
        display: flex;
        gap:2px;
    }
    .mobile-gallery-img{
        border-radius: 30px;
    }
    .line{
        flex: 0.5;
        border: .5px solid black;
        border-radius: 3px;
        background-color: #fff;

        transition: flex 500ms ease-in-out;
    }
    .line-active{
        flex: 3;
        background-color: var(--color-primary);
        border: none;
    }
    .lines-labels{
        margin-top: 10px;
        width: 100%;
        display: flex;
        justify-content: space-between;

        transition: 500ms ease-in-out;
    }
    .lines-labels > span{
        font-family: var(--font-jost);
        font-size: 16px;
        font-weight: 400;
    }
    .lines-labels > span.label-active{
        font-size: 20px;
    }
    .mobile-carousel{
        width: 100%;
        height: 280px;
        display: flex;
        align-items: center;
        overflow: hidden;
    }
    .mobile-gallery-img{
        width: 100%;
        max-height: 240px;
    }
    .mobile-carousel .alice-carousel__stage-item{
        height: auto;
    }

}