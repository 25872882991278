.cell-image{
    position: relative;
    overflow: hidden;
    border-radius: 20px;
    height: 330px;
}

.cell-image > img{
    user-select: none;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
    transform: scale(1);
    filter: blur(0);
    transition: 150ms ease-in-out;
}

.cell-title{
    user-select: none;
    position: absolute;

    top:50%;
    left:50%;

    transform: translate(-50%,-50%);

    /* top:50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%); */

    font-family: var(--font-jost);
    font-size: 30px;
    color: white;
    text-align: center;
    
    z-index: 2;
}

.cell-image:hover > img{
    transform: scale(1.05);
    filter: blur(.15em);
}

.cell-image:hover{
    cursor: pointer;
}

@media screen and (max-width: 480px) {
    .cell-image{
        width: 100%;
        height: auto;
        aspect-ratio: 1.58;
        
    }
    .cell-title{
        font-size: 30px;
    }
}