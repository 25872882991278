#Public{
    width: calc(100% - 120px);
    padding-top: 245px;
    display: flex;
    flex-direction: column;
    margin:  0 auto;
}
.public-block{
    width: 100%;
}
.public-content{
    display: flex;
    width: 100%;
    justify-content: space-between;
}
.high-tech-a{
    width: calc(50% - 10px);
    max-height: 460px;
}
.high-tech{
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 20px;
}
.coming-soon{
    position: relative;
    width: 55%;
    overflow: hidden;
    max-height: 460px;
    border-radius: 20px;
}
.coming-soon > img{
    object-fit: cover;
    width: 100%;
    height: 100%;
    /* filter: blur(.15em); */
}
.high-tech:hover{
    cursor: pointer;
}
.high-tech > img{
    object-fit: cover;
    width: 100%;
    height: 100%;
    transition: 150ms ease-in-out;
}
.high-tech:hover > img{
    transform: scale(1.05);
    filter: blur(.15em);
}

.public-title{
    z-index: 1;
    font-family: var(--font-jost);
    font-size: 26px;
    text-align: center;
    color: white;
    position: absolute;
    top:50%;
    left:50%;
    transform: translateX(-50%) translateY(-50%);
}

@media (max-width: 480px) {
    #Public{
        padding-top: 180px;
        padding-bottom: 60px;
        width: calc(100% - 40px);
        padding-inline:20px ;
        margin: 0;
    }
    .public-block{
        width: 100%;
    }
    .public-content{
        flex-direction: column;
        gap:20px
    }
    .high-tech-a{
        width: 100%;
    }
    .coming-soon{
        width: 100%;
    }
}

