.slider-item-img:hover{
    cursor: pointer;
}

@media (max-width: 480px) {
    #Hightech{
        padding-top: 180px;
        padding-bottom: 60px;
        width: calc(100% - 40px);
        padding-inline: 20px;
    }
}