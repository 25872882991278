/* Contains default styling for projects */
.project-container{
    position: relative;
    width: calc(100% - 120px);
    min-height: 100dvh;
    padding-top: 245px;
    padding-inline: 60px;
    display: flex;
    flex-direction: column;
}

.project-row{
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.project-title-container{
    font-size: 20px;
    font-family: var(--font-jost);
    font-weight: 400;

    display:flex;
    justify-content: space-between;
    align-items: flex-end;
}
.project-title{
    font-size: 30px;
    font-family: var(--font-jost);
    font-weight: 400;
    transform: translateY(-2px);
}
.project-description-text{
    font-size: 20px;
    font-family: var(--font-jost);
    margin: 50px 0 0 0;
}
.project-double{
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap:10px;
}
.row-left, .row-right{
    width: calc(50% - 20px);
    height: max-content;
}
.project-img{
    cursor: pointer;
    width: 100%;
    border-radius: 20px;
    vertical-align: top;
}
.project-single{
    width: 50%;
}
.halfed{
    width: calc(25% - 20px) !important;
}

@media screen and (max-width:480px) {
    .project-title-container{
        align-items: unset;
        flex-direction: column;
        gap:20px;
    }
    .project-row{
        flex-direction: column;
    }
    .project-title{
        font-size: 20px;
    }
    .project-city,
    .project-year,
    .project-description-text{
        font-size: 16px;
    }
    .project-gap-50{
        gap:50px;
    }
    .project-gap-10{
        gap:10px;
    }
    .project-double{
        flex-direction: column;
        gap:10px;
    }
    .row-left, .row-right{
        width: 100%;
    }
    .project-img{
        border-radius: 20px;
    }
    .project-container{
        padding-top: 180px;
        padding-inline: 20px;
        width: calc(100% - 40px);
    }
}