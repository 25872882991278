#White{
    position: relative;
    width: calc(100% - 120px);
    min-height: 100dvh;
    padding-top: 240px;
    display: flex;
    flex-direction: column;
    padding-inline: 60px;
    justify-content: space-between;
}
.wr-center{
    align-items: center;
}
.white-extra-text-1,.white-extra-text-2 {
    font-size: 20px;
    font-family: var(--font-jost);
    margin: 0 0 0 0;
}


@media screen and (max-width: 480px) {
    #White{
        padding-top: 180px;
        padding-bottom: 60px;
        width: calc(100% - 40px);
        padding-inline: 20px;
    }

    .w-mb-1{
        flex-direction: column;
        gap:50px;
        margin-bottom: 10px;
    }
    .w-mb-2-double{
        flex-direction: row-reverse;
        align-items: flex-start;
    }
    .w-mb-2-double-offset{
        margin-top: 60px;
    }
    .white-extra-text-1,
    .white-extra-text-2{
        font-size: 16px;
    }
    .w-mb-5{
        flex-direction: column-reverse;
    }
    .w-mb-2-double > img{
        width: calc(50% - 5px);
    }
}