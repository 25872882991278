#Loft{
    position: relative;
    width: calc(100% - 120px);
    min-height: 100dvh;
    padding-top: 245px;
    padding-inline: 60px;
    display: flex;
    flex-direction: column;
}

@media screen and (max-width:480px) {
    #Loft{
        padding-top: 180px;
        padding-bottom: 60px;
        width: calc(100% - 40px);
        padding-inline: 20px;
    }
}