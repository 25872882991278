/* Home */
#Home{
    width: calc(100vw - 120px);
    height: 100vh;
    background: none;
    position: relative;
    display: flex;
    align-items: flex-end;
    padding-inline: 60px;
    justify-content: space-between;
    overflow: hidden;
}

/* #Home::after{
    position: absolute;
    bottom:-150px;
    left:0;
    background: rgb(25,30,40);
    background: linear-gradient(180deg, rgba(25,30,40,1) 0%, rgba(24,228,168,1) 0%, rgba(255,255,255,0) 100%); 
    content: '';
    width: 100%;
    height: 150px;
} */
.home-title{
    z-index: 10;
    margin: 0;

    font-family: var(--font-jost);
    font-weight: normal;
    font-size: 27vw;
    line-height: .7em;
    color: black;
    display: flex;
}

.subtitle-home{
    position: absolute;
    z-index: 10;
    margin: 0;
    top:50%;
    right: 40px;
    transform: translate(40%, -50%) rotate(-90deg);
    padding-bottom: 10px;

    font-family: var(--font-jost);
    font-weight: normal;
    font-size: 30px;
}

#homeCanvas{
    position: absolute;
	top:0;
	left: 0;
	z-index: -1;
	width: 100%;
	height: 100%;
    /* border: 1px solid red */
}

#homeCanvas > img{
    visibility: hidden;
	pointer-events: none;
	position: absolute;
}


.span-wrapper{
    margin-bottom: calc(-10vw);
    right: 60px;
    position: absolute;
    bottom: 27vh;
}

.home-content{
    position: absolute;
    top: 0;
    right: 0;
    width: calc(65% - 120px);
    height: 100%;
    background-color: white;
    border-radius: 40px;
    transition: 500ms ease-in-out;
    padding: 0 60px;
}
.expanded{
    width: 100%;
}

.home-content > #main-header > header > .header-btn{
    margin-left: auto;
}

.home-content.expanded > #main-header > header > .header-btn{
    margin-left: 100px;
}

.home-content > #main-header > header > .header-logo{
    display: none;
}

.home-content.expanded > #main-header > header > .header-logo{
    display: flex;
    animation: 350ms logo-ease forwards;
}

@keyframes logo-ease {
    0%{
        opacity: 0;
        transform: translateX(200%);
    }
    100%{
        opacity: 1;
        transform: translateX(0);
    }
}

.contact-link-conatiner > a{
    color:black;
    text-decoration: none;
    display: flex;
    align-items: center;
    gap:20px;
    font-family: var(--font-jost);
    font-size: 24px;
}

.contact-link-conatiner{
    position: absolute;
    bottom: 30px;
    right: 60px;
}

.home-logo-container{
    position: relative;
    z-index: 10;
    height: max-content;
    padding-bottom: 30px;
    transform: translateX(-40px);
    opacity: 1;
    transition: opacity 500ms ease-in-out;
}

.title-home-expanded{
    opacity: 0;
}

.home-logo-container > span{
    user-select: none;
    line-height: 0;
    font-size: 28vw;
    font-family: var(--font-jost);
}

.h-l-c-last{
    z-index: 20;
    position: absolute;
}
.home-logo-container > img{
    width: 250px;
    height: 250px;
    position: absolute;
    right: -120px;
    z-index: 10;
    top: -120px;
}

.loader{
    position: relative;
    background-color:#fff;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    pointer-events: none;
}
.loader > img{
    width: 270px;
    height: 270px;
}
.loader-overflower{
    position:absolute ;
    width: 500px;
    height: 500px;
    background-color: #fff;
    animation: 1s linear slide forwards;
    filter:blur(10px)
}

@keyframes slide {
    0%{
        transform: translate(0,0) rotate(45deg);
    }
    100%{
        transform: translate(270px,270px) rotate(45deg);
    }
    
}



@media (max-width: 480px) {
    .home-content{
        border-radius: 20px 0 0 20px;
        width: 43%;
        padding-inline: 0;
    }
    #Home{
        height: 40vh;
        padding-top: 155px;

        background-image: url('../../../assets/design/mobile/mobile_hero.webp');
        background-position: left;
        background-repeat: no-repeat;
        background-size: contain;

        transition: 500ms ease-in-out;
    }
    #Home.home-mobile-expanded{
        height: 100vh;
        background-position-x:0;
        background-position-y:0 ;
        padding: 0;
        width: 100%;
    }

    .home-logo-container{
        position: absolute;
        bottom: 0;
        left: 52px;
    }

    .home-logo-container > img{
        width: 80px;
        height: 80px;
        right: -30px;
        top: -46px;
    }
    
    .home-logo-container > span{
        font-size: 39vw;
    }

    .expanded-mobile{
        width: 100%;
        border-radius: 0;
    }

    .home-logo-hidden{
        opacity: 0;
    }


    .home-title{
        font-size: 46vw;
        margin: 0 auto;
        width: calc(100% - 40px);
        line-height: 1em;
    }
    .subtitle-home{
        font-size: 16px;
        margin: 0 auto;
        width: calc(100% - 40px);
        right: 5px;
        opacity: 1;
        transition: opacity 500ms ease-in-out;
        width: max-content;
    }
    .hero-subtitle-hidden{
        opacity: 0;
    }
    #Home::after{
        bottom:-50px;
        height: 50px;
    }

    .mobile-menu-content{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        opacity: 0;
        pointer-events: none;
        margin-top: 155px;
        height: 100%;

        transition: 500ms ease-in-out;
    }
    .mobile-menu-nav{
        list-style-type: none;
        display: flex;
        flex-direction: column;
        gap:10px;
        padding-left: 20px;
    }
    .mobile-menu-nav > li > a{
        text-decoration: none;
        color: black;
        font-family: var(--font-jost);
        font-size: 20px;
        font-weight: normal;
    }
    .mobile-menu-nav > li > #lng-swt{
        display: block;
        margin-left: 0;
        text-decoration: none;
        color: black;
        font-family: var(--font-jost);
        font-size: 20px;
        font-weight: normal;
        padding: 0;
    }

    .expanded-mobile > .mobile-menu-content{
        opacity: 1;
        pointer-events: all;

        height: calc(100dvh - 185px);
    }
    .mobile-menu-content-top{
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
    }

    .contact-link-conatiner{
        position: relative;
        right: 20px;
        bottom: 15px;
    }
    .contact-link-conatiner > a{
        text-decoration: none;
        color: black;
        font-family: var(--font-jost);
        font-size: 20px;
        font-weight: normal;
    }
    .contact-link-conatiner > a > img{
        width: 30px;
    }

    .mobile-menu-content-bottom{
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
    }

    .mobile-menu-content-bottom > ul{
        display: flex;
        flex-direction: column;
        padding-left: 20px;
        list-style-type: none;
        gap:10px;
    }
    .m-last-list{
        padding-left: 0;
        margin-right: 20px;
        text-align: end;
    }
    .mobile-menu-content-bottom > ul > li >a{
        text-decoration: none;
        color: black;
        font-family: var(--font-jost);
        font-size: 20px;
        font-weight: normal;
    }


}