.about-bottom-gallery{
    display: flex;
    width: 100%;
}
.desaturate > img{
    filter: saturate(0);
}
.about-bottom-gallery > img{
    border-radius: 20px 10px 10px 20px;
    margin-right: -20px;
    width: 100%;
    transition:  150ms ease-in-out;
    max-height: 160px;
    object-fit: cover;
}
.desaturate > img:hover{
    cursor: pointer;
    filter: saturate(1.5);
    margin-top: -10px;
    border-radius: 20px;
}

@media screen and (max-width:480px) {
    .about-bottom-gallery-mobile{
        width: 100%;
        overflow-x: hidden;
        display: flex;
        margin-bottom: 70px;
        margin-top: 50px;
    }
    .gall-wrapper{
        display: flex;
        overflow-x: scroll;
        width: calc(100% - 40px);
        padding-inline: 20px;

        scroll-snap-type: x mandatory;
    }

    .gall-wrapper > img{
        scroll-snap-align: center;

    }
    .gall-wrapper > img:not(:last-child){
        margin-right: -20px;
    }
    .gall-wrapper > img:last-child{
        border-radius: 20px;
    }

    .gall-wrapper::-webkit-scrollbar {
        display: none;
     /* make scrollbar transparent */
    }
          
    .gall-wrapper {
        scrollbar-width: none; /* Firefox */
        -ms-overflow-style: none;  /* IE 10+ */
    }
    .b-g-m-img{
        filter: saturate(0);
        transition: 250ms ease-in-out;
        max-width: 330px;
        border-radius: 20px;
    }
    .gal-active{
        filter: saturate(1);
    }
}