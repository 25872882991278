.projects-gallery{
    width: calc(100% - 120px);
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    padding-top: 250px;
}
.projects-gallery-wrapper{
    width: 100%;
    display: flex;
    gap:40px
}
.projects-gallery-item{
    position: relative;
    display: flex;
    align-items: flex-end;

    height: 100%;
    background-position: center;
    background-repeat: no-repeat;

    height: 580px;
    flex: 0.5;
    cursor: pointer;
    border-radius: 80px;

    transition: flex 700ms;

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}
.projects-gallery-active{
    flex: 1;
}

.projects-gallery-item > span{
    font-family: var(--font-jost);
    font-size: 30px;
    position: absolute;
    left:50%;
    top:50%;
    transform: translate(-50%, -50%);
    transition: 500ms ease-in-out;
    color: #fff;
}

.projects-gallery-item.projects-gallery-active > span{
    font-size: 30px;
}

#p1,#p2{
    overflow: hidden;
}

.inner-image{
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    min-width: 100%;
    min-height: 100%;
    transition: none;
}

.private-animation{
    animation: private-switch 8s linear infinite 0s forwards;
	animation-play-state: paused;
}

#p1:hover .private-animation{
	animation-play-state: running;
}

.public-animation{
    animation: public-switch 4s linear infinite 0s forwards;
	animation-play-state: paused;
}

#p2:hover .public-animation{
	animation-play-state: running;
}
#p1:hover > span,
#p2:hover > span{
	color: #fff;
    left: 15%;
    top:90%;
}


@keyframes private-switch {
    0% { opacity: 1;}
	25% { opacity: 1;}
    26% { opacity: 0; z-index: 0 !important;}
    100% { opacity: 0;  z-index: 0 !important; }
}

@keyframes public-switch {
    0% { opacity: 1;}
	50% { opacity: 1;}
    51% { opacity: 0; z-index: 0 !important;}
    100% { opacity: 0;  z-index: 0 !important; }
}

@media screen and (max-width:480px) {
    .projects-gallery-wrapper{
        flex-direction: column;
        gap:10px;
    }
    .projects-gallery-item{
        flex: none;
        border-radius: 20px;
        width:100%;
        height: auto;
        aspect-ratio: 1.57;
    }
    .projects-gallery{
        width: calc(100% - 40px);
        padding-top: 180px;
        margin-bottom: 70px;
    }
    .inner-image{
        width: 120%;
    }
}