.wawe-img{
    width: calc(100% - 80px);
    height: 20px;
    margin: 0 40px;
    background-image: url('../../assets/design/wave.svg');
    background-repeat: repeat-x;
}

@media screen and (max-width:480px) {
    .wawe-img{
        width: calc(100% - 40px);
        margin: 0 20px;
    }
}