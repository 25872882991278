.top-anchor{
    position: fixed;
    right: 60px;
    bottom: 60px;
    width: 60px;
    height: 60px;
    border: 1px solid rgba(0,0,0,.5);
    border-radius: 50%;
    background-color: white;
    opacity: 0;
    z-index: 10;

    display: flex;
    align-items: center;
    justify-content: center;

    transform: translateY(200px);
    transition: 350ms ease-in-out;
}

.top-anchor.visible-anchor{
    opacity: 1;
    cursor: pointer;
    transform: translateY(0);
}