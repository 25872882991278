/* Contacts */
#Contacts{
    width: 100%;
    min-height: 100dvh;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
}
.contacts-wrapper{
    margin-top: 310px;
    display: flex;
    align-items: center;
    width: calc(100% - 120px);
    padding: 0 60px;
    margin-bottom: 150px;
}
.contacts{
    width: 60%;
    height: 400px;
    border: 1px solid black;
    border-right: none;
    border-radius: 20px 0 0 20px;
}
.popup-zone{
    width: 40%;
    height: 400px;
    border: 1px solid black;
    border-radius: 0 20px 20px 0;
    border-left: none;
}

.contacts-top{
    width: calc(100% - 80px);
    height: 50%;
    margin: 40px auto 0 auto;
    /* border-bottom: 1px solid black; */

    display: flex;
    align-items: center;
    padding-bottom: 50px;
    justify-content: space-between;
}
.top-data{
    display: flex;
    flex-direction: column;
    gap:48px;
    margin-top: 74px;
}

.top-data > a{
    font-family: var(--font-jost);
    font-size: 30px;
    color:black;
    text-decoration: none;
    width: max-content;
}
.top-data > a:hover,
.contacts-bottom > ul > li > a:hover{
    text-decoration: none;
}
.top-logo > img{
    width: 100%;
    max-width: 500px;
    margin-right: 50px;
    user-select: none;
}
.contacts-bottom{
    width: calc(100% - 80px);
    margin: 0 auto;
}
.contacts-bottom > ul{
    padding: 0;
    justify-content: space-between;
    list-style-type: none;
    margin-top: 20px;
}
.contacts-bottom > ul > li > a{
    font-family: var(--font-jost);
    font-size: 20px;
    color:black;
    text-decoration: none;
}

.popup-zone{
    display: flex;
    align-items: center;
    justify-content: center;
}

.popup-btn{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    width: 303px;
    aspect-ratio: 1;
    border: 1px solid rgba(0,0,0,.5);
    border-radius: 50%;

    transition: 250ms ease-in-out;
}

.popup-btn-text{
    display: flex;
    align-items: center;
    gap:20px;
}

.popup-btn-text>span{
    position: absolute;
    width:  max-content;
    left:65px;
    font-family: var(--font-jost);
    font-size: 24px;
    font-weight: 400;
    z-index: 10;

    transition: 250ms ease-in-out;
}
.popup-btn-text:hover > span{
    font-size: 30px;
}
.arrow-bottom{
    position: absolute;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 57px;
    aspect-ratio: 1;
    border-radius: 50%;
    background-color: var(--color-primary);
    transition: 250ms ease-in-out;

    right: 65px;
}
.popup-btn-text> .arrow-bottom> img{
    opacity: 1;

    width: 20px;
    aspect-ratio: 1;
    transition: 150ms ease-in-out;
}
.popup-btn:hover{
    cursor: pointer;
    border-color: #fff;
}

.popup-btn:hover> .popup-btn-text > .arrow-bottom{
    width: 305px;
    right: 0;
}
.popup-btn:hover> .popup-btn-text > span{
    color:black;
    opacity: .8;
    
    left: 50%;
    transform: translateX(-50%);
}
.popup-btn:hover> .popup-btn-text > .arrow-bottom > img{
    opacity: 0;
}

.contacts-list-mobile{
    display: none;
}
.contacts-list-desc{
    display: flex;
}

@media screen and (max-width:480px) {
    .contacts-wrapper{
        width:calc(100% - 40px);
        padding-inline: 20px;
        margin-top: 180px;
        flex-direction: column;
        margin-bottom: 30px;
        
    }
    .contacts,
    .popup-zone{
        width: 100%;
    }
    .contacts{
        border: 1px solid black;
        border-bottom: none;
        border-radius: 20px 20px 0 0;
    }
    .popup-zone{
        border: 1px solid black;
        border-top: none;
        border-radius: 0 0 20px 20px ;
    }

    .contacts-top{
        height: auto;
        flex-direction: column;
        align-items: flex-start;
        padding-bottom: 0;
        width: calc(100% - 40px);
    }
    .contacts-bottom{
        width: calc(100% - 40px);
    }
    .contacts-top > .top-data{
        margin-top: 40px;
        gap:10px;
    }
    .top-data{
        margin-bottom:20px ;
    }
    .top-data > a{
        font-size: 20px;
        font-weight: normal;
    }

    .contacts-list-mobile{
        display: flex;
        margin-bottom: 0;
    }
    .contacts-list-desc{
        display: none;
    }

    .left-col,.right-col{
        display: flex;
        flex-direction: column;

        font-size: 20px;
        font-weight: normal;
    }
    .right-col{
        transform: translateX(-40%);
    }

    .popup-btn{
        width: calc(100% - 40px);
    }
    .arrow-bottom{
        position: inherit;
    }
    .popup-btn-text > span{
        position: inherit;
    }
}