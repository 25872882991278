.popup-wrapper{
    position: relative;
    display: none;
    align-items: center;
    justify-content: center;


    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.65);

    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    z-index: 100;
}

.popup-show > .popup-form{
    background-color: #fff;
    border-radius: 50%;
    animation: 250ms popup-init forwards ease-in-out;
    box-shadow: 0 0 30px rgba(255, 255, 255, 0.25);
}

.popup-show{
    display: flex;
    position: fixed;
    left:0;
    top:0;
    height: 105vh;
    overflow: hidden;
    width: 100%;
}

.popup-form{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;

}
.popup-inner{
    display: flex;
    flex-direction: column;
    animation: 450ms form-init forwards ease-in-out;
}

.popup-inner > input{
    border: none;
    height: 30px;
    margin-bottom: 35px;
    border-bottom: 1px solid black;

    font-family: var(--font-jost);
    font-weight: normal;
    font-size: 20px;
}

.popup-inner > input:focus{
    border-bottom: 1px solid var(--color-primary);
    outline: none;
}
.popup-inner > input::placeholder{
    opacity: .25;
}

.popup-inner > label{
    font-family: var(--font-jost);
    font-size: 20px;
}

.popup-inner > button{
    font-family: var(--font-jost);
    font-size: 24px;
    font-weight: 400;
    width: 100%;
    padding: 15px 0 15px 0;
    background-color: #fff;
    border: 1px solid black;
    border-radius: 20px;

    transition: 250ms ease-in-out;
}

.popup-inner > button:hover{
    cursor: pointer;
    border: 1px solid var(--color-primary);
    background-color: var(--color-primary);
}

.popup-inner > button:disabled{
    border: 1px solid var(--color-primary-disabled);
    background-color: var(--color-primary-disabled);
}

.contact-icon-wrapper{
    width: 56px;
    height: 56px;
    border-radius: 50%;
    background-color: var(--color-primary);
    border: 1px solid var(--color-primary);

    display: flex;
    align-items: center;
    justify-content: center;

    transition: background-color 150ms ease-in;
}

.popup-initer:hover .contact-icon-wrapper{
    border: 1px solid rgba(0, 0, 0, 0.5);
    background-color: #fff;
}


@keyframes popup-init {
    0%{
        width: 0;
        height: 0;
    }
    100%{
        width: 700px;
        height: 700px;
        left:50%;
        top:50%;
        transform: translate(-50%, -50%);
    }
}

@keyframes form-init {
    0%{
        width: 0;
        max-width: 0;
        opacity: 0;
    }
    100%{
        width: 80%;
        max-width: 430px;
        opacity: 1;
    }
}

@media screen and (max-width:480px) {
    @keyframes popup-init {
        0%{
            width: 0;
            height: 0;
        }
        100%{
            width: 160vw;
            height: 160vw;
            left:50%;
            top:50%;
            transform: translate(-50%, -50%);
        }
    }

    .contact-icon-wrapper{
        width: 29px;
        height: 29px;
    }
    .contact-icon-wrapper > img{
        scale: .6;
    }
    
    @keyframes form-init {
        0%{
            width: 0;
            max-width: 0;
            opacity: 0;
        }
        100%{
            width: calc(100vw - 50px);
            opacity: 1;
        }
    }
}