/* About */
#About{
    width:calc(100% - 120px);
    padding-inline: 60px;
    padding-top: 230px;
    padding-bottom: 90px;
    display: flex;
    flex-direction: column;
}
.about-content{
    display: flex;
    gap:70px;
}
.about-logo{
    font-family: var(--font-jost);
    font-size: 30px;

    position: relative;
    padding-left: 43px;
    display: flex;
    flex-direction: column;
    width: max-content;
}
.about-logo{
    margin-bottom: 312px;
}
.about-green{
    position: absolute;
    color: var(--color-primary);
    transform: rotateZ(-90deg);
    top: 44px;
    left: -54px;
    letter-spacing: 3px; 
}

.about-text{
    font-family: var(--font-jost);
    font-size: 20px;
    max-width: 710px;
    padding-right: 75px;
}

.about-video{
    width: 100%;
    max-width: 465px;
    margin-bottom: -24px;
    border-radius: 20px;
}
.about-video[poster]{
    object-fit: cover;
}
.about-name-title{
    /* transform: translateY(-42px); */
    margin-left: 22px;
    display: flex;
    flex-direction: column;
    font-family: var(--font-jost);
    font-size: 20px;
}
.about-name{
    font-size: 30px;
}
.about-content-right{
    width: 40%;
}
.video-holder{
    width: 100%;
    max-width: 465px;
    aspect-ratio: 3/4;
    background-image: url('../../assets/about/Poster.jpg');
    background-repeat: no-repeat;
    border-radius: 20px;
    background-size: 100% 100%;
}

@media screen and (max-width:480px) {
    #About{
        width: calc(100% - 40px);
        padding-inline: 20px;
        padding-top: 180px;
        padding-bottom: 0px;
    }
    .about-logo{
        font-size: 20px;
        font-weight: normal;
        padding-left: 32px;
        margin-bottom: 40px;
    }
    .about-green{
        top: 26px;
        left: -34px;
        letter-spacing: 2.4px;
    }
    .about-content{
        flex-direction: column;
        gap:20px;
    }
    .about-text{
        font-size: 16px;
        font-weight: normal;
        padding-right: 0;
    }
    .about-name-title{
        /* margin-left: 35%; */
        font-weight: normal;
        font-size: 16px;
        /* transform: translateY(-22px); */
    }
    .about-content-right{
        width: 70%;
    }
    .about-name{
        font-size: 20px;
    }

}