/* Slider */
.image-slider{
    position: fixed;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding:0 100px ;

    width: calc(100vw - 200px);
    height: 100dvh;
    top:0;
    left: 0;
    z-index: 1000;
    background-color: rgba(255,255,255,.5);
    backdrop-filter: blur(.5em);
}
.slider-image{
    display: block;
    margin: 0 auto;
    height: 80vh;
    border-radius: 30px;
}
.slider-close{
    position: fixed;
    top:60px;
    right: 60px;
    z-index: 100;
    background: none;
    border: none;
}
.slider-close:hover{
    cursor: pointer;
}
.slider-close > img{
    width: 25px;
}

/* overwrite */
.alice-carousel__dots{
    display: none;
}
.alice-carousel__prev-btn, .alice-carousel__next-btn {
    font-size: 20px;
    width: 47%;
    margin-left: 1.5%;
}
.alice-carousel__prev-btn-item, .alice-carousel__next-btn-item {
    color:black;
}

.alice-carousel__prev-btn-item:hover, .alice-carousel__next-btn-item:hover {
    color:black;
}

.alice-carousel__next-btn-wrapper{
    width: 50px;
    height: 70px;
    background-image: url('../../assets/design/slider-right.svg');
    background-repeat: no-repeat;
    background-size: 50px;
    background-position: bottom left;
}
.alice-carousel__prev-btn-wrapper{
    width: 50px;
    height: 70px;
    margin-left: auto;

    background-image: url('../../assets/design/slider-left.svg');
    background-repeat: no-repeat;
    background-size: 50px;
    background-position: bottom right;
}
.alice-carousel__next-btn-item,
.alice-carousel__prev-btn-item
{
    margin-top: 0px;
    width: 45px;
    height: 70px;
    opacity: 0;
}

.alice-carousel__prev-btn-wrapper:hover{
    cursor: pointer;
    background-image: url('../../assets/design/slider-left-hover.svg');
}
.alice-carousel__next-btn-wrapper:hover{
    cursor: pointer;
    background-image: url('../../assets/design/slider-right-hover.svg');
}

 @media (max-width: 480px) {
    .image-slider{
        padding:0 20px ;
        width: calc(100vw - 40px);
    }

    .slider-image{
        width: 100%;
        height: auto;
    }
    .alice-carousel__next-btn-wrapper,
    .alice-carousel__prev-btn-wrapper{
        width: 30px;
        height: 30px;
        background-size: 30px;
    }

    .slider-close{
        top:20px;
        right:20px;
    }
    .alice-carousel__dots{
        display: none;
    }
    .alice-carousel__stage-item{
        padding-inline: 5px;
        height: 130vw;
        display: inline-flex;
        align-items: center;
    }
    
 }