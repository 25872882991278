#Private{
    width: 100%;
    padding-top: 245px;
}

.private-table{
    margin: 0 auto;
    width: calc( 100% - 120px);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.fusion, .mono{
    width: 44%;
}
.white, .loft{
    width: 55%;
}
.fusion, .white{
    margin-bottom: 1%;
}


@media screen and (max-width:480px) {
    .private-container{
        padding-top: 180px;
    }
    .private-table{
        flex-direction: column;
        width: calc(100% - 40px);
        gap:10px;
    }
    #Private{
        padding-top: 0;
        padding-bottom: 60px;
    }
}