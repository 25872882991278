#Terms{
    width: calc(100% - 120px);
    padding-inline: 60px;
    padding-top: 150px;
    font-family: var(--font-jost);
}
#Terms > p{
    font-size: 18px;
    margin: 0;
}
.terms-title{
    font-size: 32px;
    font-weight: 600;
}
#Terms > h2{
    font-size: 18px;
    margin: 0;
    margin-top: 1em;
    font-weight: 400;
}
#Terms > ul{
    margin: 0;
    list-style-type: none;
}
#Terms > ul > li{
    margin: 0;
    font-size: 18px;
}
#Terms > .terms-en-h2{
    margin-bottom: .5em ;
    font-weight: 600;
}


#Terms > ul > li {
    text-indent: -19px;
}

#Terms > ul > li:before {
    content: "–";
}


@media  screen and (max-width:480px) {
    #Terms{
        width: calc(100% - 40px);
        padding-inline: 20px;
        padding-bottom: 40px;
    }
}