/* header */
.header-container{
    position: absolute;
    top:0;
    left: 0;
    width:100%;

    display: flex;
    flex-direction: column;
    align-items: flex-end;

    font-family: var(--font-jost);
    font-weight: normal;
    font-size: 22px;

    z-index: 20;
}

/* .header-logo-green{
    content: url('../../assets/design/logo-green-cropped.png');
}
.header-logo-white{
    content: url('../../assets/design/logo.png');
} */
header{
    position: relative;
    width: calc(100% - 120px);
    margin: 0 60px;
    display: flex;
    align-items: flex-end;
    flex-direction: row;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    border-bottom: 2px solid rgba(0, 0, 0, .5);
    padding: 40px 0 20px 0;
}
.header-nav{
    display: flex;
    align-items: center;
    flex-direction: row;
    gap:100px;
}
.header-nav-a{
    position: relative;
    text-decoration: none;
}

.header-nav-a::after{
    position: absolute;
    content: '';
    bottom:0;
    left:0;
    width: 100%;
    height: 2px;
    background: #000;
    opacity: .5;

    transform: scaleX(0);
    transform-origin: right;

    transition: transform 150ms ease-in;
}

.header-nav-a:hover::after{
    transform: scaleX(1);
    transform-origin: left;
}

.header-btn{
    background: none;
    border: none;
    font-size: 20px;
    margin-bottom: 3px;
    margin-left: 100px;
    cursor: pointer;
    color: #212529;
}

.header-title{
    margin-top: 20px;
    margin-right: 69px;
    text-align: start;
    line-height: 1.2em;
    user-select: none;
}
.header-lng-popup{
    background-color: #fff;
    border-radius: 5px;
    padding: 5px;
    display: flex;
    flex-direction: column;
    gap:5px;
}
.header-lng-popup > button{
    color: black;
    font-family: var(--font-jost);
    font-size: 22px;
    background: none;
    border: none;
}
.header-lng-popup > button:hover{
    cursor: pointer;
    text-decoration: underline;
}
.lng-switch{
    position: relative;
}

.mobile-menu-toggle-btn{
    display: none;
    flex-direction: column;
    justify-content: space-between;
    width: 28px;
    height: 18px;
    background: none;
    border: none;
    padding: 0;  
}
.toggle-line{
    width: 100%;
    height: 2.3px;
    background-color: rgba(0, 0, 0, .5);

    transform: rotateZ(0) translateY(0) translateX(0);
    opacity: 1;
    transition: 200ms ease-in-out;
}

.header-mobile-btn-toggled > .fst{
    transform: rotateZ(45deg) translateY(5px) translateX(6px);
}
.header-mobile-btn-toggled > .md{
    opacity: 0;
}
.header-mobile-btn-toggled > .end{
    transform: rotateZ(-45deg) translateY(-5px) translateX(6px);
}

.header-logo{
    position: absolute;
    display: flex;
    flex-direction: column;
    right:0;
    top:21px;
    justify-content: center;
    gap:20px;
    transform: translateX();
}
.header-logo:hover{
    cursor: pointer;
}
.logo-labels{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}
.logo-labels > span{
    width: 100%;
    text-align: end;
    line-height: 120%;
    font-family: var(--font-jost);
    font-size: 20px;
}

.mobile-menu{
    display: none;
}




@media (max-width: 480px) {
    header{
        width: calc(100vw - 40px);
        margin: 0 20px;
        padding: 35px 0 10px 0;
        align-items: center;
        border-bottom: none;

        background-color: transparent;
        width: calc(100% - 40px);
        margin: 0 20px;
        border-bottom: 0px solid black;

    }
    .header-nav,
    .header-btn{
        display:none ;
    }
    .header-title{
        font-size: 12px;
        margin-right: 22px;
        margin-top: 10px;
    }
    .mobile-menu-toggle-btn{
        display: flex;
        margin-left: 85%;
        transition: 500ms ease-in-out;
    }
    .mobile-menu-toggled_header{
        position: fixed !important;
    }
    .mobile-btn-left{
        margin-left: 0;
    }

    header.header-m-toggled{
        border-bottom: 1px solid black;
        padding-bottom: 25px;
        z-index: 19;
    }


    .header-logo{
        display: flex !important;
        transform: translateX(400%);
        opacity: 0;
        align-items: flex-end;
        transition: 500ms ease-in-out;
    }
    .header-logo > img{
        width: 50px;
    }
    .header-logo > .logo-labels{
        font-size: 16px;
        font-weight: normal;
    }


    header.header-m-toggled > .header-logo{
        transform: translateY(-3px);
        opacity: 1;

        display: flex !important;
    }

    .mobile-menu{
        display: flex;
        position: absolute;
        top:-105dvh;
        left:0;
        width: 100%;
        height: 100dvh;
        background-color: #fff;
        z-index: 18;
        overflow-x: hidden;

        transition: 500ms ease-in-out;
    }

    .mobile-menu-toggled{
        top:0;
    }
    .mobile-menu-content_outer{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 125px;
        height: 80dvh;
        transition: 500ms ease-in-out;
        width: 100%;
    }
    .shar-menu-texture{
        position: absolute;
        right: -55%;
        height: 60%;
        top: 50%;
        transform: translateY(-40%);
        width: 130%;
    }
    .logo-labels > span{
        font-size: 16px;
    }
}